import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom";
import style from './navbar.module.css'
import sideImg from "../../assets/icons8-arrow-30.png"
import Warrow from "../../assets/arNew.png"
import DashImg from "../../assets/icons8-dashboard-24.png"
import DashNew from "../../assets/dashNew.png"
import UserImg from "../../assets/icons8-users-23.png"
import CustNew from "../../assets/custNew.png"
import BlogImg from "../../assets/icons8-blog-20.png"
import BlgNew from "../../assets/blgNew.png"
import AppointmentsImg from "../../assets/icons8-dentist-book-20.png"
import AppNew from "../../assets/appNew.png"
import TestimonialsImg from "../../assets/icons8-feedback-22.png"
import TestNew from "../../assets/testNew.png"
import TeamImg from "../../assets/icons8-team-24.png"
import TeamNew from "../../assets/tmNew.png"
import DesignersImg from "../../assets/icons8-designers-24.png"
import DsrNew from "../../assets/dsrNew.png"
import EnquiresImg from "../../assets/enquires1.png"
import EnqNew from "../../assets/enqNew.png"
import CollectionsImg from "../../assets/collections.png"
import ClcNew from "../../assets/clcNew.png"
import CategorysImg from "../../assets/icons8-category-24.png"
import CtrNew from "../../assets/ctrNew.png"
import ProductsImg from "../../assets/products.png"
import PrcNew from "../../assets/prcNew.png"
import NewslettersImg from "../../assets/newsletters.png"
import NewsNew from "../../assets/newsNew.png"
import DiscountsImg from "../../assets/discounts.png"
import DisNew from "../../assets/disNew.png"
import SettingsImg from "../../assets/settings.png"
import StgNew from "../../assets/stgNew.png"
import OrdersImg from "../../assets/orders.png"
import OrdNew from "../../assets/ordNew.png"
import StorImg from "../../assets/storImg.png"
import StoNew from "../../assets/stoNew.png"

const Navitem = ({ active }) => {
    const path = window.location.pathname
    const roleId = localStorage.getItem('role_id')
    const [change, setChange] = useState(1)
    const navData = [
        { id: 1, name: 'dashboard', img: DashImg, wimg: DashNew, link: "/dashboard" },
        { id: 2, name: 'customers', img: UserImg, wimg: CustNew, link: "/customers" },
        { id: 15, name: 'orders', img: OrdersImg, wimg: OrdNew, link: "/orders" },
        { id: 16, name: 'instore orders', img: StorImg, wimg: StoNew, link: "/instore" },
        { id: 3, name: 'blogs', img: BlogImg, wimg: BlgNew, link: "/blogs" },
        { id: 4, name: 'appointments', img: AppointmentsImg, wimg: AppNew, link: "/appointments" },
        { id: 5, name: 'testimonials', img: TestimonialsImg, wimg: TestNew, link: "/testimonials" },
        { id: 6, name: 'team', img: TeamImg, wimg: TeamNew, link: "/team" },
        { id: 7, name: 'designers', img: DesignersImg, wimg: DsrNew, link: "/designers" },
        { id: 8, name: 'enquires', img: EnquiresImg, wimg: EnqNew, link: "/enquires" },
        { id: 9, name: 'collections', img: CollectionsImg, wimg: ClcNew, link: "/collections" },
        { id: 10, name: 'categories', img: CategorysImg, wimg: CtrNew, link: "/categories" },
        { id: 11, name: 'products', img: ProductsImg, wimg: PrcNew, link: "/products" },
        { id: 12, name: 'newsletters', img: NewslettersImg, wimg: NewsNew, link: "/newsletters" },
        { id: 13, name: 'discounts', img: DiscountsImg, wimg: DisNew, link: "/discounts" },
        { id: 14, name: 'home Page', img: SettingsImg, wimg: StgNew, link: "/home" },
    ]
    useEffect(() => {
        if (path === '/dashboard') {
            setChange(1)
        } else if (path === '/customers') {
            setChange(2)
        } else if (path === '/blogs') {
            setChange(3)
        } else if (path === '/blogs/add-blog') {
            setChange(3)
        } else if (path === '/blogs/edit-blog/:id') {
            setChange(3)
        } else if (path === '/appointments') {
            setChange(4)
        } else if (path === '/testimonials') {
            setChange(5)
        } else if (path === '/testimonials/add-testimonials') {
            setChange(5)
        } else if (path === '/testimonials/edit-testimonials/:id') {
            setChange(5)
        } else if (path === '/team') {
            setChange(6)
        } else if (path === '/team/add-team') {
            setChange(6)
        } else if (path === '/team/edit-team/:id') {
            setChange(6)
        } else if (path === '/designers') {
            setChange(7)
        } else if (path === '/designers/add-designers') {
            setChange(7)
        } else if (path === '/designers/edit-designers/:id') {
            setChange(7)
        } else if (path === '/enquires') {
            setChange(8)
        } else if (path === '/collections') {
            setChange(9)
        } else if (path === '/categories') {
            setChange(10)
        } else if (path === '/categories/add-categories') {
            setChange(10)
        } else if (path === '/categories/edit-categories/:id') {
            setChange(10)
        } else if (path === '/products') {
            setChange(11)
        } else if (path === '/products/add-products') {
            setChange(11)
        } else if (path === '/products/edit-products/:id') {
            setChange(11)
        } else if (path === '/newsletters') {
            setChange(12)
        } else if (path === '/newsletters/add-newsletters') {
            setChange(12)
        } else if (path === '/discounts') {
            setChange(13)
        } else if (path === '/discounts/add-discounts') {
            setChange(13)
        } else if (path === '/discounts/edit-discounts/:id') {
            setChange(13)
        } else if (path === '/home') {
            setChange(14)
        } else if (path === '/home/usp/add-usp') {
            setChange(14)
        } else if (path === '/home/usp/edit-usp/:id') {
            setChange(14)
        } else if (path === '/home/top-header-slider/add-top-header-slider') {
            setChange(14)
        } else if (path === '/home/top-header-slider/edit-top-header-slider/:id') {
            setChange(14)
        } else if (path === '/home/home-page-bottom-slider/add-home-page-bottom-slider') {
            setChange(14)
        } else if (path === '/home/home-page-bottom-slider/edit-home-page-bottom-slider/:id') {
            setChange(14)
        } else if (path === '/home/project-slider/add-project-slider') {
            setChange(14)
        } else if (path === '/home/project-slider/edit-project-slider/:id') {
            setChange(14)
        } else if (path === '/home/home-page/add-home-page') {
            setChange(14)
        } else if (path === '/home/home-page/edit-home-page/:id') {
            setChange(14)
        } else if (path === '/orders') {
            setChange(15)
        } else if (path === '/instore') {
            setChange(16)
        } else if (path === '/instore/add-order') {
            setChange(16)
        }
    }, [Link])
    return (
        <>
            {navData.map((item, index) => {
                switch (true) {
                    case Number(roleId) == 2 && item.id == 6:
                        break;
                    case Number(roleId) == 2 && item.id == 7:
                        break;
                    case Number(roleId) == 2 && item.id == 9:
                        break;
                    case Number(roleId) == 2 && item.id == 13:
                        break;
                    case Number(roleId) == 2 && item.id == 14:
                        break;
                    default:
                        return <div key={index} style={{ background: item.id === change ? "#168C98" : "#fff" }}>
                            <Link to={item.link}>
                                <div className={style.navitem_section}>
                                    <div className={style.navitem_start}>
                                        <img src={item.id === change ? item.wimg : item.img} alt='nav item image' />
                                        {active ? '' : <div className={style.navitem_label} style={{ color: item.id === change ? '#fff' : '' }}>{item.name}</div>}
                                    </div>
                                    {active ? '' : <img src={item.id === change ? Warrow : sideImg} alt='side image' style={{ height: "18px" }} />}
                                </div>
                            </Link>
                        </div>
                }


            })}
        </>
    )
}
export default Navitem;