import React, { useState, useEffect, useRef } from "react";
import Layout from "../../components/layout/Layout";
import style from "./products.module.css";
import PageHeader from "../../components/pageHeader/PageHeader";
import Tables from "../../components/tables/Tables";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  getProductsData,
  productsDataDelete,
  sigleProductsDataAction,
  statusChangeAction,
  editBlankData,
  getCategoryData,
} from "../../store/slices/productsSlice";
import { Space, Tag, Switch, Input, Button } from "antd";
import BigLoader from "../../components/bigLoader/BigLoader";
import AddImg from "../../assets/icons8-add-24.png";
import EditImg from "../../assets/editNew.svg";
import DeleteImg from "../../assets/icons8-delete-24 .png";
import { useNavigate } from "react-router";
import ColorLoader from "../../components/colorLoader/ColorLoader";
import banner1 from "../../assets/images3.png";
import banner2 from "../../assets/images2.png";
import ActionImg from "../../assets/actionImg.png";
import closeImg from "../../assets/smallClose.png";
import { mainUrl } from "../../utils/common";
import RevertLoader from "../../components/revertLoader/RevertLoader";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import ShowIcon from "../../assets/showIcon.png";
import VariantPopup from "../../components/variantPopup/VariantPopup";

const Products = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.products.data);
  const loading = useSelector((state) => state.products.loading);
  const deleteLoad = useSelector((state) => state.products.deleteLoad);
  const [state, setState] = useState();
  const [deleteId, setDeleteId] = useState();
  const [showAction, setShowAction] = useState(false);
  const [actionId, setActionId] = useState();
  const [ids, setIds] = useState();

  const searchInput = useRef(null);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [variantShow, setVariantShow] = useState(false);
  const [variantData, setVariantData] = useState();

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters, selectedKeys, confirm, dataIndex) => {
    clearFilters();
    setSearchText("");
    confirm({
      closeDropdown: false,
    });
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() =>
              clearFilters &&
              handleReset(clearFilters, selectedKeys, confirm, dataIndex)
            }
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  useEffect(() => {
    dispatch(getProductsData());
    dispatch(getCategoryData());
  }, []);
  useEffect(() => {
    setState(data);
  }, [data]);
  const columns = [
    {
      title: "Product Name",
      dataIndex: "name",
      width: 200,
      id: "name",
      render: (text) => <a>{text}</a>,
      ...getColumnSearchProps("name")
    },
    // {
    //   title: "Quantity",
    //   width: 100,
    //   dataIndex: "quantity",
    //   id: "quantity",
    //   render: (text) => <a>{text}</a>,
    // },
    {
      title: "Category",
      dataIndex: "category",
      width: 100,
      id: "category",
      render: (text) => <a>{text ? text : "NA"}</a>,

    },
    {
      title: "Price",
      width: 100,
      dataIndex: "price",
      id: "price",
      render: (text) => <a>{text ? text : "NA"}</a>,
    },
    {
      title: "Size",
      width: 100,
      dataIndex: "size",
      id: "size",
      render: (text) => <a>{text ? text : "NA"}</a>,
    },
    {
      title: "Variant Show",
      width: 150,
      render: (record) => (
        <Space size="middle">
          <div
            className={style.product_variant_show}
            onClick={() => variantsShowHandler(record.subProducts)}
          >
            <img src={ShowIcon} alt="show icon" />
            <div>Variants</div>
          </div>
          {variantShow ? (
            <VariantPopup backHandler={closeVariantPopup} data={variantData} />
          ) : (
            ""
          )}
        </Space>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      id: "status",
      width: 200,
      render: (status, record, index) => {
        const onToggle = (checked) => {
          setIds(checked);
          dispatch(statusChangeAction(checked));
        };
        return (
          <Space>
            <Switch
              style={{ background: status === 1 ? "#168C98" : "" }}
              checked={status === 1 ? true : false}
              onChange={() => onToggle(record._id)}
            />
            <div
              className={style.users_switch_btn}
              style={{
                border:
                  status === 1 ? "1px solid #168C98" : "1px solid #3E3636",
              }}
            >
              {status === 1 ? (
                deleteLoad && ids === record._id ? (
                  <RevertLoader />
                ) : (
                  "active"
                )
              ) : deleteLoad && ids === record._id ? (
                <RevertLoader />
              ) : (
                "inactive"
              )}
            </div>
          </Space>
        );
      },
    },
    // {
    //   title: "Description",
    //   dataIndex: "description",
    //   id: "description",
    //   // render: (text) => <a>{text ? text : "NA"}</a>,
    //   ellipsis: true,
    // },
    {
      title: "Image",
      dataIndex: "image",
      id: "image",
      width: 200,
      render: (_, { images }) => (
        <div className={style.designers_images_pa}>
          {images &&
            images.map((img, index) => {
              return (
                <div key={index} className={style.designers_images_st}>
                  <img
                    crossOrigin="anonymous"
                    src={img.image ? `${mainUrl()}/${img.image}` : "-"}
                    alt="image"
                  />
                </div>
              );
            })}
        </div>
      ),
    },

    {
      title: "Material",
      dataIndex: "material",
      id: "material",
      width: 200,
      render: (text) => <a>{text ? text : "NA"}</a>,
    },
    {
      title: "Action",
      id: "action",
      fixed: "right",
      width: 140,
      render: (_, record) => (
        <Space size="middle">
          <div
            className={style.delete_edit_hand_1}
            onClick={() => editHandler(record)}
          >
            <img src={EditImg} alt="edit image" />
            <div>edit</div>
          </div>
          {/* <div className={style.delete_edit_hand} onClick={()=>deleteHandler(record)}>
              {deleteLoad && record._id=== deleteId ? <ColorLoader/>:
              <>
              <img src={DeleteImg} alt='edit image'/>
               <div>delete</div>
               </>
               }
              </div> */}
        </Space>
      ),
    },
  ];

  const variantsShowHandler = (record) => {
    setVariantShow(true);
    setVariantData(record);
  };
  const closeVariantPopup = () => {
    setVariantShow(false);
  };

  const actionShowHandler = (record) => {
    setActionId(record._id);
    setShowAction(true);
  };
  const actionHideHandler = () => {
    setActionId();
    setShowAction(false);
  };
  const addHandler = () => {
    dispatch(editBlankData());
    navigate("/products/add-products");
  };
  const editHandler = (record) => {
    dispatch(sigleProductsDataAction(record._id));
    navigate(`/products/edit-products/${record._id}`);
  };
  const deleteHandler = (record) => {
    setDeleteId(record._id);
    dispatch(productsDataDelete(record._id));
  };
  return (
    <Layout>
      <div className={style.products_section}>
        <PageHeader
          name="products details"
          btnName="add products"
          img={AddImg}
          addHandler={addHandler}
        />
        {loading ? (
          <BigLoader />
        ) : (
          <>
            <Tables datas={state} columns={columns} scrollVal={1700} />
            <div style={{ textTransform: "capitalize" }}>
              total products -{" " + state ? state?.length : ""}
            </div>
          </>
        )}
      </div>
    </Layout>
  );
};
export default Products;
